import { Fade } from "react-awesome-reveal";
import React, { useState } from "react";
// import { Button } from "../../../common/Button";
import "./style.css";
import { Link } from "react-router-dom";
import Button2 from "../../common/Button2";
import { Row, Col } from "antd";
import Content from "../../content/FAQ.json";
import { SvgIcon } from "../../common/SvgIcon";
import KostenRadentscheid from "../AktuelleThemen/Reiter/KostenRadentscheid";
import SectionLine from "../SectionLine";


interface QuestionDefaultProps {
    question: string;
    answer: string[];
    aufzaehlung: boolean;
    link?: JSX.Element;
}
const QuestionDefault = ({question, answer, link }:QuestionDefaultProps) => {

    const [geoeffnet, updateGeoeffnet] = useState(0);
    const aendern = () => {
        if (geoeffnet === 0){
            updateGeoeffnet(1)
        } else {
            updateGeoeffnet(0)
        }
    };
    if (geoeffnet === 1){
        return(
            <div className="question">
                <a onClick = {aendern}>
                <div className = "heading">
                    <h3>{question}</h3>
                    <SvgIcon src="close.svg" height="20px" width="fit-content"/>
                </div>
                </a>
                {
                    answer.map(x => {
                            return(<p><div dangerouslySetInnerHTML={{__html: x}} /></p>);
                        })
                }
                {
                    link !== undefined? link : <></>
                }
            </div>
        );
    } else {
        return(
            <a onClick = {aendern}>
                <div className="question">
                    <div className = "heading">
                        <h3>{question}</h3>
                        <SvgIcon src="scroll-down.svg" height="20px" width="fit-content"/>
                    </div>
                </div>
            </a>
        );
    }

}

const Unterstuetzen = () => {
    return (
        <div className="question" id = 'faqhelfen'>
            <h3>Wie kann ich den Radentscheid unterstützen?</h3>
            <ul>
                <li>
                    Überzeuge dein Umfeld und rede über den Radentscheid - Jede Stimme zählt!
                </li>
                <li>
                    Um mehr Menschen über über die Radentscheide zu informieren haben wir einen Flyer im A5-Format entworfen und gedruckt. <br/>
                    Wenn du in deiner Nachbarschaft flyern möchtest, trag dich hier mit dem Gebiet das du übernehmen
                    möchtest ein und wir melden uns bei dir: So verteilen wir Flyer in ganz Göttingen.
                    <Button2
                        onClick={() => {
                            window.open("https://survey.academiccloud.de/index.php/986188?newtest=Y&lang=de")
                        }}
                        content="Beim Flyern helfen!"
                    ></Button2>
                </li>
            </ul>
        </div>
    );
}

const FAQ = () => {
    const [kosten, updateKosten] = useState<boolean>(false);
    return (
        <Fade>
            <Row justify="space-between" align="middle" id={"faq"}>
                <div className="faq-wrapper">
                    {/* <h1>FAQ: Häufig gestellte Fragen</h1> */}
                    <SectionLine how = "center"/>
                    <br/><br/>
                    <Unterstuetzen/>
                    <QuestionDefault
                        question="Führt der Radentscheid zu Kürzungen in den Bereichen Soziales und Kultur?"
                        aufzaehlung={false}
                        answer={[
                            "Die Verwaltungsspitze kommuniziert derzeit, dass sich die Kosten des Radentscheids auf 100 Mio. € beliefen und Kürzungen in sozialen und kulturellen Bereichen verursachen. [u.a. Göttinger Tageblatt, 16.4.24]",
                            "Dies ist eine bewusste Streuung von Falschinformationen, denn die Kostenschätzung der Stadtverwaltung vor Beginn der Unterschriftensammlung im August 2023 spricht eine ganz andere Sprache.",
                            "Die Inhalte der Begehren überschneiden sich, Bund und Länder fördern Investitionen in die Radinfrastruktur mit 50 - 70% und die Inflation wird mit 15% p.a. deutlich überschätzt, sodass mit Kosten von 32 Mio. EUR in den sechs Jahren bis 2030 zu rechnen ist.",
                            "Berücksichtigt man jetzt noch, dass bereits ein Etat für Radverkehr im Haushalt vorgesehen ist, wird deutlich, dass die Notwendigkeit von Kürzungen in den Bereichen Kultur und Soziales reine Stimmungsmache sind."
                        ]}
                        link={
                            kosten === true ? <>
                                <KostenRadentscheid/>
                                <a onClick={() => {
                                    updateKosten(false)
                                }}>einklappen</a>
                            </> : <>
                                <a onClick={() => {
                                    updateKosten(true)
                                }}>
                                    mehr: Detaillierte Ausführung zu den Kosten des Radentscheids
                                </a>
                                {/* <Button2 content="" onClick={() => {updateKosten(true)}}/> */}
                            </>
                        }
                    />

                    {
                        Content.map(
                            el => {
                                return (
                                    <QuestionDefault
                                        question={el.question}
                                        aufzaehlung={el.aufzaehlung}
                                        answer={el.answer}
                                    />
                                );
                            }
                        )
                    }
                </div>
            </Row>
        </Fade>
    );
}

export default FAQ;