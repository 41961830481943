import React, {lazy} from "react";
import { Row } from "antd";
import ScrollToTop from "../../common/ScrollToTop";
import { Fade } from "react-awesome-reveal";
import "./style.css";
import { PngIcon } from "../../common/PngIcon";

import SectionLine from "../../components/SectionLine";
import FAQ from "../../components/FAQ";
import Container from "../../common/Container";
import Button2 from "../../common/Button2";


const FaqPage = () => {
    return(
        <Container>
            <Fade>
                <ScrollToTop/>
                <Row id = 'intro'></Row>
                <div className="faqpage-wrapper">
                    <div id='top'/>
                    <div className="intro">
                        <div className="left">
                            <h1>FAQ</h1>
                            <h2>
                                Häufig gestellte Fragen <br/>rund um den Radentscheid.
                            </h2>
                            <ul>
                                <li>
                                    Ab sofort könnt ihr uns eure Fragen zum Radentscheid stellen und das
                                    Radentscheid-Team
                                    antwortet auf eure Fragen.
                                </li>
                                <li>
                                    Alle Antworten werden hier im FAQ veröffentlicht.
                                </li>
                            </ul>
                            <a href="https://survey.academiccloud.de/index.php/971558?newtest=Y&lang=de"><Button2
                                content="Hier Frage stellen" onClick={() => {
                            }}/></a>
                            <br/><br/>
                        </div>
                        <div className="right">
                            <PngIcon
                                src="_radentscheid-logo-14-04-2023.jpg"
                                width="250px"
                                height="fit-content"/>
                        </div>
                    </div>
                    <FAQ/>
                </div>
            </Fade>
        </Container>
    );
}

export default FaqPage;
