import { submitValueProps } from "./eingabe";

const ARG1 = `Zu aller erst:
Der Radentscheid ist gut für die Demokratie, denn Menschen haben die Möglichkeit über Maßnahmen direkt vor ihrer Haustür mit zu bestimmen - und hierbei ist es erstmal ganz egal wie sie sich entscheiden.`;
const ARG2 = `Die Trennung von Rad- und Autoverkehr steigert die Sicherheit für beide Verkehrsarten.
Dies ist übrigens auch der Grund, warum Auto- und Radverbände für Protected Bike Lanes werben.`;
const ARG3 = `Der Göttinger Busverkehr ist im Radentscheid mitgedacht. Die Maßnahmen sind so ausgewählt, dass der Göttinger Busverkehr so wenig wie möglich beeinträchtigt wird und am Ende von einem sinkenden Verkehrsaufkommen profitiert.`;
const ARG4 = `Die Maßnahmen des Radentscheids steigern Komfort und Sicherheit im Radverkehr. Mehr Menschen werden das Fahrrad als bevorzugtes Verkehrsmittel ansehen - und das ganz ohne Verbote.`;
const ARG5 = `Wenn mehr Menschen auf das Fahrrad umsteigen, hat dies positive Auswirkungen auf alle Verkehrsteilnehmer:innen.`;
const ARG6 = `Weniger motorisierter Verkehr, steigert die Luftqualität im Stadtbereich.`;
const ARG7 = `Busse werden pünktlicher, wenn das Auto-Verkehrsaufkommen sinkt.`;
const ARG8 = `Kinder können sicher mit dem Fahrrad zum Kindergarten oder zur Schule fahren.`;
const ARG9 = `Der Verkehrssektor ist bundesweit das krankende Kind in Sachen Klimatransformation. Göttingen könnte hier ein Zeichen setzen.`;
const ARG10 = `Der Radentscheid verändert die Fahrradstraßen so, dass sie schließlich echte Fastlanes für Zweiradfahrende sein werden.`;
const ARG11 = `Der Radentscheid fordert in Begehren 1 einen zuverlässigen Winterdienst auch auf Radwegen: Bei Wintereinbruch könnte weiter sicher Fahrrad gefahren werden.`;
const ARG12 = `Die Wohnlagen am Stadtrand und in den umliegenden Bereichen der Innenstadt profitieren am meisten vom Radentscheid. Der Radentscheid schließt die Lücken im Radverkehrsnetz und ermöglicht schnellen Transitverkehr: von Weende bis nach Geismar und von Grone bis in die Oststadt.`;
const ARG13 = `Auch genervt von Ampelschaltungen für Fahrradfahrende? Der Radentscheid bringt Grünpfeilschilder für Fahrradfahrende und eine optimierte Verkehrsführung, sodass Linksabbiegen an Ampelkreuzungen für Fahrradfahrende bald keine Tagesaufgabe mehr ist.`;
const ARG14 = `Neben Kindern zählen auch Menschen im höheren Alter zu den Gruppen, für die es auf dem Fahrrad schnell zu unsicher wird. Die Maßnahmen des Radentscheids zielen darauf ab, auch für Senior:innen das Fahrrad eine reale Option werden zu lassen.
Fakt ist, dass Menschen in den Niederlanden etwa 10 Jahre länger Fahrradfahren als hierzulande.`;
const ARG15 = `Radverkehr, der nicht zwingend durch die Innenstadt muss, erhält mit dem Radentscheid die Möglichkeit schnell und sicher außenherum zu fahren. Dies entlastet die Innenstadt.`;
const ARG16 = `Fahrradfahren ist gut für das Herz- und Kreislaufsystem - am besten, wenn es in alltägliche Wege integriert ist.`;
const ARG17 = `Einbahnstraßensysteme mit abgetrennten Protected Bike Lanes bewirken, dass Autos nicht mehr minutenlang hinter langsamen Fahrrädern herfahren müssen.`;
const ARG18 = `Leitschwellen zwischen Protected Bike Lanes und Auto-Fahrspuren verhindern den Wechsel von Fahrrad- auf Autospur und schützen sowohl Auto- als auch Radfahrende.`;
const ARG19 = `Protected Bike Lanes schützen Fahrrad- und Autofahrende vor gefährlichen Überholmanövern.`;
const ARG20 = `Geschützte Räume für den Radverkehr bewirken auf stark befahrenen Straßen, dass die Fahrräder und E-Scooter seltener auf den Gehwegen fahren.`;
const ARG21 = `Die Leitschwellen der Protected Bike Lanes geben ein höheres Gefühl von Sicherheit, wenn Autos die Fahrräder mit wenig Abstand überholen.`;
const ARG22 = `Straßenräume mit hohem Radverkehrsanteil werden sicherer und übersichtlicher, wenn weniger Autos am Fahrbahnrand parken.`;
const ARG23 = `Der Radentscheid fördert die barrierefreie Mobilität aller Menschen durch die Verwendung niveaugleicher Rampensteine und die Absenkung von Bordsteinen an Einfahrten.`;
const ARG24 = `Sogenannte Bettelampelschaltungen (die Ampel wird nur Grün, wenn der Anforderungstaster gedrückt wurde) gehören mit der Umsetzung des Radentscheids der Vergangenheit an.`;


export interface argumentRequestProps {
    V_RAD:number|null;
    V_AUTO:number|null;
    V_BUS:number|null;
    V_FUSS:number|null;
    V_ANDERE:number|null;
    KINDER:number|null;
    ARBEITSORT_STADTGEBIET:number|null;
    ARBEITSORT_AUSSERHALB:number|null;
    INNENSTADT:number|null;
    STADTRAND:number|null;
    UMLIEGEND:number|null;
    ALTER:number;
}

export interface argForDisplay {
    arg: string;
    hierarchie: number;
};
export const prepareInput = (input:submitValueProps) => {
    let r: argumentRequestProps = {
        V_RAD: null,
        V_AUTO: null,
        V_BUS: null,
        V_FUSS: null,
        V_ANDERE: null,
        KINDER: null,
        ARBEITSORT_STADTGEBIET: null,
        ARBEITSORT_AUSSERHALB: null,
        INNENSTADT: null,
        STADTRAND: null,
        UMLIEGEND: null,
        ALTER: 0
    }

    // Verkehrsmittel
    if (input.verkehrsmittel === 'Fahrrad'){
        r.V_RAD = 1
    }
    else if (input.verkehrsmittel === 'Auto'){
        r.V_AUTO = 1
    }
    else if (input.verkehrsmittel === 'Zu Fuß'){
        r.V_FUSS = 1
    }
    else if (input.verkehrsmittel === 'Bus/ Bahn'){
        r.V_BUS = 1
    }
    else {
        r.V_ANDERE = 1
    }

    // Kinder
    if (input.kinder === 'Ja'){
        r.KINDER = 1;
    }

    // Wohnort
    console.log(input.wohnort)
    if (['Innenstadt'].includes(input.wohnort)){
        r.INNENSTADT = 1;
    } else if (['Oststadt','Südstadt','Weststadt','Nordstadt'].includes(input.wohnort)){
        r.UMLIEGEND = 1;
    } else {
        r.STADTRAND = 1;
    }

    // Arbeitsort
    if (input.arbeitsort === 'Stadtgebiet Göttingen'){
        r.ARBEITSORT_STADTGEBIET = 1
    } else {
        r.ARBEITSORT_AUSSERHALB = 1
    }
    // Alter
    r.ALTER = +input.alter


    return (r);
}
export const getArguments = (input:submitValueProps) => {

    let req = prepareInput(input)
    let res:argForDisplay[] = [];

    if (true){res.push({arg: ARG1, hierarchie: 0});};
    if (req.STADTRAND === 1 || req.UMLIEGEND === 1){res.push({arg: ARG12, hierarchie: 0});};
    if (req.ALTER >= 51){res.push({arg: ARG23, hierarchie: 0});};
    if (req.V_FUSS === 1){res.push({arg: ARG24, hierarchie: 0});};
    if (req.ALTER >= 51){res.push({arg: ARG14, hierarchie: 0});};
    if (req.V_BUS === 1){res.push({arg: ARG3, hierarchie: 0});};
    if (req.V_RAD === 1){res.push({arg: ARG4, hierarchie: 0});};
    if (req.V_RAD === 1 || req.V_AUTO === 1 || req.V_BUS === 1 || req.V_FUSS === 1 || req.V_ANDERE === 1){res.push({arg: ARG5, hierarchie: 0});};
    if (req.V_RAD === 1 || req.V_FUSS === 1 || req.V_ANDERE === 1){res.push({arg: ARG6, hierarchie: 1});};
    if (req.V_BUS === 1){res.push({arg: ARG7, hierarchie: 1});};
    if (req.KINDER === 1){res.push({arg: ARG8, hierarchie: 1});};
    if (true){res.push({arg: ARG9, hierarchie: 1});};
    if (req.V_RAD === 1){res.push({arg: ARG10, hierarchie: 0});};
    if (req.V_RAD === 1 || req.V_FUSS === 1){res.push({arg: ARG11, hierarchie: 0});};
    if (req.V_RAD === 1){res.push({arg: ARG13, hierarchie: 0});};
    if (req.V_AUTO === 1 || req.INNENSTADT === 1 || req.STADTRAND === 1 || req.UMLIEGEND === 1){res.push({arg: ARG15, hierarchie: 0});};
    if (req.ARBEITSORT_STADTGEBIET === 1 || req.ALTER >= 51){res.push({arg: ARG16, hierarchie: 0});};
    if (req.V_AUTO === 1){res.push({arg: ARG17, hierarchie: 0});};
    res.push({arg:'Dies sind die Vorteile von Protected Bike Lanes für dich:', hierarchie: 0})
    if (req.V_RAD === 1 || req.V_AUTO === 1){res.push({arg: ARG2, hierarchie: 1});};
    if (req.V_RAD || req.V_AUTO === 1){res.push({arg: ARG18, hierarchie: 1});};
    if (req.V_RAD === 1 || req.V_AUTO === 1){res.push({arg: ARG19, hierarchie: 1});};
    if (req.V_FUSS === 1 || req.V_ANDERE === 1 || req.KINDER === 1){res.push({arg: ARG20, hierarchie: 1});};
    if (req.V_RAD === 1){res.push({arg: ARG21, hierarchie: 1});};
    if (req.V_RAD === 1 || req.V_ANDERE){res.push({arg: ARG22, hierarchie: 1});};

    return res;
}

