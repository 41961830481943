import Button2 from "../../../common/Button2";
import "./Podcast.css"
import SpotifyPlayer from "../../SpotifyPlayer";
import SectionLine from "../../SectionLine";

const Podcast = () => {
    return (
        <div className="podcast-wrapper">
            <h3>Radentscheid Podcast</h3>
            <h6>Infos und Talk zum Radentscheid gibt es auf allen Platformen:</h6>
            <div className="button-line-wrapper">
                <div className="button-line-element">
                    <a href="https://open.spotify.com/show/20NzfrYLQHyqfaAouqPBgW?si=cb2d1b07779c4689" target="_blank"
                    ><Button2
                        content="auf Spotify"
                        onClick={() => {
                        }}
                        style={{backgroundColor: "green", color: "white"}}
                    /></a>
                </div>
                <div className="button-line-element">
                    <a href="https://music.amazon.de/podcasts/6ee23140-50ae-4d98-8752-b460195da2ea/radentscheid-g%C3%B6ttingen"
                       target="_blank"
                    ><Button2
                        content="auf Amazon Music"
                        onClick={() => {
                        }}
                        style={{backgroundColor: "blue", color: "white"}}
                    /></a>
                </div>
                <div className="button-line-element">
                    <a href="https://podcasts.apple.com/de/podcast/radentscheid-g%C3%B6ttingen/id1745927314"
                       target="_blank"
                    ><Button2
                        content="auf Apple Podcasts"
                        onClick={() => {
                        }}
                        style={{backgroundColor: "purple", color: "white"}}
                    /></a>
                </div>
            </div>

            <br/>
            <h6>Folge 4: Was fehlt uns im Göttinger Verkehr?</h6>
            <p>Mit Julius Gottschalk, studentischer Senator der Georg-August-Universität und Rainer Worm vom ADFC und VCD</p>
            <SpotifyPlayer podcastId={"3khWrfC9lp3lPGCVfkhCkl"}/>

            <h6>Folge 3: „Peinlich, beinahe eine Bankrotterklärung der Verkehrsplanung“</h6>
            <p>Heinrich Detering‘s Sicht auf Verkehrsplanung und Diskussionskultur in Göttingen</p>
            <SpotifyPlayer podcastId={"2eucLgeXX6wcakQV0qnBHb"}/>

            <h6>Folge 2: Mythen, Kritik und Fakes zum Radentscheid</h6>
            <SpotifyPlayer podcastId={"0Gs2wrbUGOJ1OZbxevnISV"}/>

            <h6>Folge 1: Radentscheide - darum geht’s.</h6>
            <SpotifyPlayer podcastId={"6a70qkgnzoNqG8ssjURP67"}/>

            <SectionLine how={"center"}/>
            <br/>
            <h6>Der Radentscheid im Deutschlandfunk Länderreport</h6>
            <a href="https://www.deutschlandfunkkultur.de/radverkehr-in-goettingen-warum-ueber-das-fahrrad-gestritten-wird-dlf-kultur-34145c3e-100.html"
               target="_blank">Anhören auf Deutschlandfunk.de</a> (07:50 Minuten)
            <p>Der Länderreport Podcast vom 27. Mai 2024: Vor Ort mit Francisco Welter-Schultes</p>

            <br/>
            <h6>Der Radentscheid im Stadtradio</h6>
            <a href="https://www.stadtradio-goettingen.de/permalink_to?objid=e125137"
               target="_blank">Anhören auf Stadtradio-Goettingen.de</a> (23:28 Minuten)
            <p>Der Stadtradio Podcast vom 15. Dezember 2023: Martin Hulpke-Wette und Francisco Welter-Schultes im
                Interview</p>
        </div>
    )
}

export default Podcast;