import React, {useEffect, useState} from "react";
import { Fade } from "react-awesome-reveal";
import './index.css';

import Button2 from "../../../../common/Button2";

import Container from "../../../../common/Container";
import FullWidthContainer from "../../../../common/FullWidthContainer";
import {CatSlider, CustDropdown, NumSlider} from "../../../../common/Slider";
import { submitValueProps } from "./eingabe";

import { getArguments, argForDisplay, prepareInput, argumentRequestProps } from "./arguments";
import { JsxElement } from "typescript";
import { scrollTo } from "../../../../common/scrollTo";

// interface argumentProps {
//         ID_NAME:string ;
//         REIHENFOLGE:number;
//         ARGUMENT:string;
//         HIERARCHIE:number;
//         V_RAD:number|null;
//         V_AUTO:number|null;
//         V_BUS:number|null;
//         V_FUSS:number|null;
//         V_ANDERE:number|null;
//         KINDER:number|null;
//         ARBEITSORT_STADTGEBIET:number|null;
//         ARBEITSORT_AUSSERHALB:number|null;
//         INNENSTADT:number|null;
//         STADTRAND:number|null;
//         UMLIEGEND:number|null;
//         ALTER_MIN:number|null;
//         ALTER_MAX:number|null;
// }


interface AusgabeProps {
    submittedValue: submitValueProps | undefined;
    backToSelection?: React.Dispatch<React.SetStateAction<string>>;
};


const Argument = (arg:argForDisplay) => {

    let r: JSX.Element;
    if (arg.hierarchie === 0) {
        r = <p>{arg.arg}</p>
    } else if (arg.hierarchie >0) {
        r = <ul><li>{arg.arg}</li></ul>
    } else {
        r = <p>invalid HIERARCHIE {arg.hierarchie}</p>
    }

    return(
        <div className="Argument">
            {r}
        </div>
    )

}




const Ausgabe = ({submittedValue, backToSelection}: AusgabeProps) => {
    const [data, setData] = useState<argForDisplay[]>();
    const url = "https://dev.radentscheid-goe.de/api/rest.php"

    const r:argumentRequestProps | undefined = (submittedValue !== undefined) ? prepareInput(submittedValue): undefined;

    let PP:any;
    if (r !==undefined){
        PP = <>
            <p>Anz. Argumente: {data?.length}</p>
            <p>V_RAD: {r.V_RAD}</p>
            <p>V_AUTO: {r.V_AUTO} </p>
            <p>V_BUS: {r.V_BUS} </p>
            <p>V_FUSS: {r.V_FUSS}</p>
            <p>V_ANDERE: {r.V_ANDERE}</p>
            <p>KINDER: {r.KINDER}</p>
            <p>ARBEITSORT_STADTGEBIET: {r.ARBEITSORT_STADTGEBIET}</p>
            <p>ARBEITSORT_AUSSERHALB: {r.ARBEITSORT_AUSSERHALB}</p>
            <p>INNENSTADT: {r.INNENSTADT}</p>
            <p>STADTRAND: {r.STADTRAND}</p>
            <p>UMLIEGEND: {r.UMLIEGEND}</p>
            <p>ALTER: {r.ALTER}</p>
        </>
    } else {
        PP = <></>
    }


    useEffect(
        () => {
            if (submittedValue === undefined){

            } else {
                setData(
                    getArguments(submittedValue)
                );
            }

        }, [submittedValue]
    )


    return (
        <>
            {/* {PP} */}
            <p><i>Mögliche Gründe für dein 2x "Ja" am 09. Juni 2024:</i></p>
            {
                data?.map(
                    el => {return(<Argument {...el}></Argument>)}
                )
            }
        {/* <p>Alter: {String(submittedValue?.alter)}</p>
        <p>Arbeitsort: {submittedValue?.arbeitsort}</p>
        <p>Kinder: {submittedValue?.kinder}</p>
        <p>Verkehrsmittel: {submittedValue?.verkehrsmittel}</p>
        <p>Wohnort: {submittedValue?.wohnort}</p> */}
        {
            backToSelection !== undefined ? <Button2
                    content="Zurück zur Übersicht"
                    onClick={() => {
                        backToSelection("intro");
                        scrollTo("aktuelle-themen");
                    }}
                />
            : <></>
        }
        </>
    )
}

export default Ausgabe;