import React, {useState, useEffect} from "react";

import "./index.css";
import Button2 from "../../common/Button2";
import Container from "../../common/Container";
import axios from "axios";
import { Link } from "react-router-dom";

const Newsletter = () => {
    const [mail, setMail] = useState<string>("");
    const [color, setColor] = useState<string>("gray");

    const [state, setState] = useState<string>("eingabe");
    const [response, setResponse] = useState<string>("nothing");

    const checkState = (s:string) => {
        if (s === ""){
            setColor('gray')
        } else if (!s.includes("@")) {
            setColor('red');
        }
        else {
            setColor('green');
        }
    }

    const handleSubmit = () => {
        if (!mail.includes("@")) {
            setColor('red');
        } else {
            setColor('green');
            const url = "https://radentscheid-goe.de/api/server.php";

            let fData = new FormData();
            fData.append('mail', mail);

            axios.post(url, fData)
            .then(
                response => setResponse(response.data)
            )
            .catch(
                error => {console.log(error); setState("fehler");}
            )
        }
    };

    // triggered on change of response
    useEffect(
        () => {
            if (response === "nothing"){
                setState("eingabe");
            } else if (response === "SUCCESS") {
                setState("abgeschlossen");
            } else if (response === "ERROR"){
                setState("fehler");
            } else {
                setState("eingabe");
            }
        }, [response]
    )

    interface NewsletterKachelProps {
        title: string;
        content:string;
        date: string;
        url: string;

    }

    const NewsletterKachel = ({title, date, content, url}:NewsletterKachelProps) => {
        return (
            <div className="kachel" onClick={() => {
                window.location.href = url;
                window.scrollTo(0, 0);
            }}
                 onMouseEnter={() => {
                     document.body.style.cursor = 'pointer';
                 }}
                 onMouseLeave={() => {
                     document.body.style.cursor = 'default';
                 }}>
                <h3>Veröffentlicht am {date}</h3>
                <h2>{title}</h2>
                <p>{content}<br/><i>Weiterlesen...</i></p>
            </div>
        );
    }
    const Anmeldung = <>
        <div>
            <p>
                Trage einfach deine E-Mail-Adresse ein und verpasse
                nichts mehr von GöttingenZero und
                davon wie's mit dem Radentscheid weitergeht.</p>
            <input
                about="text"
                value = {mail}
                onChange = {(e) => {
                    checkState(`${e.target.value}`);
                    setMail(`${e.target.value}`);
                }}
                style = {{border: `solid thick ${color}`}}
            />
        </div>
        <Button2
            content="anmelden"
            onClick={handleSubmit}
        />
        <div>
                <Link to = "./abmeldung-newsletter">Hier kannst du dich wieder vom Newsletter abmelden.</Link>
        </div>
        </>

    const Erfolg = <>
        <div className="erfolg">
                <p>Du wurdest dem Verteiler des Newsletters erfolgreich hinzugefügt! :-)</p>
        </div>
    </>

    const Fehler = <>
        <div className="fehler">
                <p>Das hat leider nicht geklappt! Probiere es gleich noch einmal oder schreibe uns eine E-Mail und wir fügen dich manuell hinzu. :-)</p>
        </div>
        <Button2
            content = "Neu eingeben"
            onClick={() => {setState("eingabe")}}
        />
    </>


    return (
        <Container>
            <div className="newsletter-wrapper">
                <div>
                    <h1>Unser Newsletter</h1>
                    <div className="letters">
                        <NewsletterKachel
                            date={"19. November 2024"}
                            title={"1. Newsletter"}
                            content={"Unter anderem mit Update zum Radentscheid und Klimaplan 2030"}
                            url={"/#/newsletter/1-2024"}
                        />
                    </div>
                </div>
                <div>
                    <h2>Melde dich an</h2>
                    {
                        state === 'eingabe' ? Anmeldung : (state === 'abgeschlossen' ? Erfolg : Fehler)
                    }
                </div>
            </div>
        </Container>
    )
}

export default Newsletter;