import React from "react";
import "./style.css";

import Container from "../../common/Container";

const BackButton = () => {
    const handleBack = () => {
        window.history.back();
    };

    return (
        <button onClick={handleBack} style={buttonStyle}>
            &#8592; Zurück
        </button>
    );
};

// Stil für den Button
const buttonStyle = {
    display: 'inline-block',
    padding: '6px',
    color: '#fdc60c',
    backgroundColor: '#fff',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
};

const Newsletter01 = () => {
    return (
        <div>
            <Container>
                {BackButton()}
            </Container>
            <div style={{backgroundColor: '#fdc60a'}}>
                <div style={{width: '100%', height: '500vh', overflow: "visible", backgroundColor: '#fdc60a'}}>
                        <iframe
                            src="/newsletter/1-2024.htm"
                            style={{width: '100%', height: '100%', border: 'none'}}
                            title="Newsletter 1-2024"
                        />
                </div>
            </div>
        </div>
    );
}

export default Newsletter01;