import React from "react";
import Container from "../../common/Container";
import KostenRadentscheid from "../../components/AktuelleThemen/Reiter/KostenRadentscheid";
import { Link } from "react-router-dom";
import FullWidthContainer from "../../common/FullWidthContainer";



const KostenInfo = () => {
    return(
        <>
        <Container>
            <KostenRadentscheid/>
        </Container>
        <FullWidthContainer>
            <div style={{backgroundColor: "lavender", padding: "4em", marginTop: "1em", marginBottom: "1em"}}>
            <Container>
                <h1>Weiterführende Links:</h1>
                <ul style = {{listStyleImage: "url(https://radentscheid-goe.de/img/icons/G0_Logo_Pfeil.png)"}}>
                    <li><Link to = "faq">Häufige Fragen (FAQ)</Link></li>
                    <li><Link to = "rad-o-mat">Rad-O-Mat</Link></li>
                    <li><Link to = "../news">Berichterstattung über den Radentscheid</Link></li>
                </ul>
            </Container>
            </div>
        </FullWidthContainer>
        </>
    )
}

export default KostenInfo;