import React from "react";
import SectionLine from "../../components/SectionLine";
import { Fade } from "react-awesome-reveal";
import { PngIcon } from "../../common/PngIcon";

import "./style.css";
import Button2 from "../../common/Button2";

const GuteGruende = () => {
    return(<>
{/* Kopf */}
            <Fade>
                <div className="gutegruende-wrapper">
                    <div id='top'/>
                    <div className="intro">
                        <div className="left">
                            <h1>Gute Gründe für den Radentscheid</h1>
                            <h2>
                                Ein Sammelwerk aus Motivationen <br/>für den Radentscheid.
                            </h2>
                            <div className="button-wrapper-cust">
                                <a href="mailto:goettingenzero@posteo.de">
                                    <Button2 onClick={() => {
                                    }} content="Weitere Ideen? Schreibe uns eine Mail!"></Button2>
                                </a>
                            </div>
                        </div>
                        <div className="right">
                            <PngIcon
                                src="_radentscheid-logo-14-04-2023.jpg"
                                width="250px"
                                height="fit-content"/>
                        </div>
                    </div>
                    <SectionLine how="center"/>
                    {/* Content */}
                    <div className="content">
                        <h4>Radfahren steigert die Lebensqualität:</h4>
                        <ul>
                            <li>Radfahren macht Spaß</li>
                            <li>Radfahren ist leise</li>
                            <li>Man kommt in Kontakt mit seiner Umgebung (nicht wie im abgeschotteten Kfz)</li>
                            <li>Zur Arbeit radeln macht wach</li>
                            <li>Bewegung macht glücklich</li>
                            <li>Radfahren hält aktiv und ist gesund</li>
                            <li>In den Niederlanden fahren Menschen im Alter zehn Jahre länger Rad als hierzulande</li>
                            <li>Radfahren hält aktiv</li>
                            <li>Radfahren ist gesund</li>
                        </ul>

                        <h4>Insbesondere auch die der Kinder:</h4>
                        <ul>
                            <li>Kinder können mit dem Fahrrad selbstständig unterwegs sein, die Stadt um sich herum erkunden und Freunde treffen</li>
                            <li>Sichere Radverkehrswege machen unabhängig von Elterntaxis</li>
                            <li>Radfahren ermöglicht Kindern und Jugendlichen Freiheit</li>
                            <li>Radfahren macht Kinder selbstständiger und unabhängiger</li>
                        </ul>

                        <h4>Radfahren nutzt den öffentlichen Raum viel besser:,</h4>
                        <ul>
                            <li>Fahrend und parkend sind Räder den Autos in vielem weit voraus</li>
                            <li>Der Einzelhandel in der Innenstadt und kleinere Geschäfte profitieren.</li>
                            <li>Viele Autofahrten in der Stadt sind kürzer als 3 Kilometer. Solche Strecken sind für die meisten Menschen leicht mit dem Fahrrad zu bewältigen.</li>
                            <li>Durch E-Bikes, Lastenräder & co. sind heute viele Fahrten mit dem Fahrrad möglich, für die
                                früher ein Auto benutzt wurde</li>
                            <li>Insbesondere ältere Menschen haben durch E-Bikes die Möglichkeit mehr Alltagswege, auch auf
                                Strecken mit Steigungen, mit dem Fahrrad zu bewältigen</li>
                            <li>Für die Verkehrswende muss man das Rad nicht neu erfinden. Nur sichere Wege dafür schaffen</li>
                            <li>Niemand muss zwei Tonnen Metall durch die Gegend fahren, nur um einzukaufen, die Kinder zum
                                Kindergarten zu bringen, zur Arbeit zu gelangen, oder Freunde zu besuchen</li>
                            <li>Auf eine Gesamtnote von 3,51 im ADFC-Fahrradklimatest kann man nicht stolz sein</li>
                            </ul>

                        <h4>Öffentlicher Raum:</h4>
                        <ul>
                            <li> Öffentlicher Raum ist begrenzt und nicht vermehrbar. Wir können aber über die
                                Aufteilung und Nutzung entscheiden.
                            </li>
                            <li> Auf der Fläche eines Autos können zwölf Fahrräder parken.</li>
                            <li> Radfahren nutzt öffentlichen Verkehrsraum effizienter.</li>
                            <li> Mehr Radverkehr mindert Stau.</li>
                            <li> Diejenigen, die tatsächlich auf das Auto angewiesen sind kommen dann besser an ihr
                                Ziel.
                            </li>
                            <li> Auch Busse stecken dann weniger oft im Verkehr fest.</li>
                            <li> Fahren mehr Menschen mit dem Rad, wird weniger öffentlicher Raum für fahrende und
                                parkende Kfz benötigt.
                            </li>
                            <li> Die Entsiegelung von Flächen hilft gegen Überflutung bei Starkregen.</li>
                            <li> Mehr Stadtgrün macht Menschen glücklicher, zufriedener und gesünder.</li>
                        </ul>

                        <h4>Radfahren verbessert die Atmosphäre</h4>
                        <ul>
                            <li>Radfahren spart CO2-Emissionen. Es leistet einen wichtigen Beitrag zum Klimaschutz</li>
                            <li>Radfahren spart auch Giftgase (Schwefel- und Stickoxide)</li>
                            <li>Fahrräder produzieren viel weniger Feinstaub als Autos</li>
                            <li>Fahrräder sind leiser</li>
                            <li>Der Radverkehr ist sicherer als der Autoverkehr</li>
                        </ul>


                        <h4>Umwelt und Klima:</h4>
                        <ul>
                            <li> Für ein klimaneutrales Göttingen bis 2030 braucht es eine schnelle Verkehrswende</li>
                            <li> Die Emissionen im Verkehrssektor sinken viel zu langsam</li>
                            <li> E-Autos lösen viele der grundlegenden Probleme des Verkehrs nicht. Sie haben den selben
                                Flächenverbrauch, produzieren den selben Stau, sind bei 50 km/h ähnlich laut und
                                verursachen die selben Unfälle wie Autos mit Verbrennermotor. Sie verursachen erhebliche
                                Emissionen bei der Produktion. Die Umweltprobleme aufgrund der großen Batterien sind
                                weithin bekannt.
                            </li>
                            <li> Für eine Verkehrswende in Göttingen braucht es vor allem eine Stärkung des Fuß- und
                                Radverkehrs, gepaart mit starkem ÖPNV und bedarfsgerechter E-Mobilität
                            </li>
                        </ul>


                        <h4>Gesundheit:</h4>
                        <ul>
                            <li> Körperlich fitte Menschen kommen besser mit Hitzewellen klar. So gesehen ist mehr
                                Radverkehr eine aktive Minderung der Folgen der Klimakrise
                            </li>
                            <li> Bewegung ist gesund</li>
                            <li> Radfahren und Bewegung verbessern den Schlaf</li>
                            <li> Abgase und Feinstaub durch Reifenabrieb von Kfz verursachen Atemwegserkrankungen</li>
                            <li> Dauerhafter Autolärm verursacht Stress</li>
                        </ul>

                        <h4>Radfahren schont private und öffentliche Kassen:</h4>
                        <ul>
                            <li> Radfahren spart Geld - sowohl Einzelne, wie die Gesellschaft</li>
                            <li> Radfahren spart Geld als Verkehrsmittel und durch seine Infrastruktur</li>
                            <li> Radfahren senkt die Gesundheitskosten erheblich</li>
                            <li>Die Kosten eines Autos sind besonders für Menschen mit geringem Einkommen eine große
                                Belastung. Wir wollen, dass sich möglichst viele Menschen davon befreien können.
                            </li>
                            <li>Jeder mit dem Auto zurückgelegte Kilometer verursacht etwa 30 Cent Folgekosten für die
                                Gesellschaft. Etwa durch Gesundheitsschäden aufgrund von Lärm- und Feinstaubbelastung,
                                Unfälle, Umwelt- und Klimaschäden, hohen Platzverbrauch, und vieles mehr.
                            </li>
                            <li>Jeder mit dem Fahrrad zurückgelegte Kilometer spart die Gesellschaft etwa 30 Cent.</li>
                            <li> Fahrradinfrastruktur ist deutlich günstiger als solche für Kfz.</li>
                            <li>Die Belastungen durch Lärm- und Feinstaubbelastung sind sozial ungleich verteilt.</li>

                        </ul>

                        <h4>Spezifisches zum Radentscheid:</h4>
                        <ul>
                            <li>Die Stadt kann das Henne-Ei-Problem zwischen Radverkehr und der dafür wichtigen
                                Infrastruktur lösen
                            </li>
                            <li>Die Verkehrsplanung ist einer der wichtigsten kommunalen Aufgabenbereiche mit einer
                                großen Auswirkung auf Umwelt und Klima
                            </li>
                            <li>“Der hohe Stellenwert des Fahrrades für die Alltagsmobilität ist heute tatsächlich nur
                                in wenigen Straßenräumen ablesbar.” (RadVEP) - Soll heißen: Göttingen ist eine Stadt in
                                der viele Menschen Rad fahren, obwohl sie zumeist nicht sehr fahrradfreundlich gebaut
                                ist
                            </li>
                            <li>Die Niederlande haben vor 50 Jahren ihre Städte auch auf das Auto ausgerichtet und
                                erlebten die selben Probleme wie wir. Dann haben sie sehr konsequent umgesteuert. Nicht
                                alles hat auf Anhieb funktioniert. Aber es brauchte Mut die Stadt wieder so zu bauen,
                                dass man fürs Radfahren nicht mehr mutig sein braucht.
                            </li>
                            <li>Baulich getrennte führung für den Radverkehr kommt besonders Kindern und älteren
                                Menschen zu gute
                            </li>
                            <li>Eine Entflechtung der Verkehrsarten macht den Radverkehr sicherer</li>
                            <li>Dazu gehört Fahrradstraßen von Kfz-Durchgangsverkehr zu befreien</li>
                            <li>In etwa 30 deutschen Städten laufen, oder liefen bereits Radentscheide als
                                Bürgerbegehren. Die Erfahrung zeigt: Je konkreter Maßnahmen benannt werden, desto mehr
                                wird hinterher umgesetzt. Deshalb ist das konkrete Begehren 2 "Radverkehrsanlagen in der
                                Kernstadt" so wichtig.
                            </li>
                            <li>Über eine solche richtungsweisende Entscheidung sollte ganz Göttingen demokratisch
                                entscheiden.
                            </li>
                        </ul>
                    </div>
                    <SectionLine how="center"/>
                </div>
            </Fade>
        </>
    );
}

export default GuteGruende;