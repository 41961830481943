import { Fade } from "react-awesome-reveal";
import "./style.css";
import Container from "../../common/Container";
import FullWidthContainer from "../../common/FullWidthContainer";

interface Props {
    how:string;
}

export const SectionLineIMG = (props:Props) => {
    return(
        <FullWidthContainer>
            <div style = {{backgroundImage: `url(${props.how})`}} className="section-img">

            </div>
        </FullWidthContainer>
    )
}

const SectionLine = (props:Props) => {
    if (props.how === "left"){
        return(
        <Fade direction = "right">
            <Container>
            <div className = "line-wrapper">
                <hr style = {{marginLeft: 0}}/>
            </div>
            </Container>
        </Fade>
            );
    } else if (props.how === "right"){
        return(
        <Fade direction = "left">
            <Container>
            <div className = "line-wrapper">
                <hr style = {{marginRight: 0}}/>
            </div>
            </Container>
        </Fade>
        );
    } else {
        return(
        <Fade direction = "up">
            <Container>
            <div className = "line-wrapper">
                <hr/>
            </div>
            </Container>
        </Fade>
        );
    }
}

export default SectionLine;