import React, {lazy} from "react";
import { Row,} from "antd";
import { Fade } from "react-awesome-reveal";
import "./style.css";
import Flyer from "../../components/Flyer";
import { PngIcon } from "../../common/PngIcon";
import Container from "../../common/Container";
import ScrollToTop from "../../common/ScrollToTop";
import { Link } from "react-router-dom";


const FlyerPage = () => {
    return(

        <Container>
          <div style={{textAlign: "right", justifyContent: "right", width: "100%", display: "flex", marginTop: ".2em"}}>
            <img src = "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg" style={{height: "1em", marginRight: ".2em"}}/>
            <Link to = "../flyer-en">english version</Link>
          </div>
        <ScrollToTop/>
        <Row id = 'intro'></Row>
            <div className="flyerpage-wrapper">
                <div id='top'/>
                <div className="intro">
                    <div className="left">
                        <div className="text">
                            <h1>Web-Flyer</h1>
                            <h2>
                                Der Radentscheid kurz und bunt.
                            </h2>
                        </div>
                    </div>
                    <div className="right">
                        <img
                            src="./img/png/_radentscheid-logo-14-04-2023.jpg"
                            alt="radentscheid-logo"
                        />
                    </div>
                </div>
                <Flyer/>
            </div>
        </Container>
    )
}

export default FlyerPage;