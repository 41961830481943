import React from "react";
import FullWidthContainer from "../../common/FullWidthContainer";
import Container from "../../common/Container";

import "./index.css";
import { HashLink } from "react-router-hash-link";
import SectionLine from "../../components/SectionLine";
import IntroNeu from "../../components/IntroNeu";
import ScrollToTop from "../../common/ScrollToTop";
import Dashboard from "../../components/Dashboard";
import Supporter from "../../components/Supporter";
import Downloads from "../../components/Downloads";
import Slideshow from "../../components/Slideshow";
import YoutubeVideo from "../../components/YoutubeVideo";
import DownloadsEn from "../../components/DownloadsEn";

export interface SectionContainer {
    children: React.ReactNode;
    id?: string;
    style?: any;
  };
  
const SectionContainer = ({ children, id, style }: SectionContainer) => (
<FullWidthContainer>
    <div style = {style} id = {id} className="section-container">
        <Container>
        {children}
        </Container>
    </div>
    <SectionLine how = "center"/>
</FullWidthContainer>
);
interface ContentLinkProps {
    children: React.ReactNode;
    id: string;
}
const ContentLink = ({children, id}:ContentLinkProps) => {
    return(<HashLink to = {id}><li>{children}</li></HashLink>)
}

const EnglishSummary = () => {

    const y = "#FFF9E5";
    const plakatelist:string[] = [
            'img/wahlplakate/web-abgabe.png',
            'img/wahlplakate/web-auto.png',
            'img/wahlplakate/web-friedländer.png',
            'img/wahlplakate/web-kind.png'
        ];

    return (
        <div className="english-summary-wrapper">
        <IntroNeu language="en"/>

        <ScrollToTop />

        <SectionContainer style = {{backgroundColor: y}} id = "content">
            <h1>Content</h1>
            <ul>
                <ContentLink id = "#process">Process of a citizen referendum</ContentLink>
                <ContentLink id = "#eligible">Who is eligible to vote?</ContentLink>
                <ContentLink id = "#main-goals">What are our main goals?</ContentLink>
                <ContentLink id = "#ways-to-vote">Ways to Vote</ContentLink>
                <ContentLink id = "#retrospect">The collection of signatures in retrospect</ContentLink>
                <ContentLink id = "#supporter">Supporters</ContentLink>
                <ContentLink id = "#join-us">How to join us</ContentLink>
                <ContentLink id = "#downloads">Downloads</ContentLink>
            </ul>
        </SectionContainer>

        <SectionContainer style = {{backgroundColor: "white"}} id = "process">
            <h1>Process of a citizen referendum</h1>
            <p>
                A citizen referendum consists generally of two separate phases:
            </p>
                <ul>
                    <li>First: Collection of a given number of signatures (7.5% of eligible voters) to enable second phase. Each signature is checked for validity by the municipal government.</li>
                    <li>Second: municipal election whether the referedum will be accepted or not.</li>
                </ul>
        </SectionContainer>

        <SectionContainer  style = {{backgroundColor: y}} id ="eligible">
            <h1>Who is eligible to vote?</h1>
            <p>You are eligible to vote, if ...</p>
            <ul>
                <li>you are an EU-citizen and </li>
                <li>you are at least 16 years old and</li>
                <li>your primary residence is in Göttingen.</li>
            </ul>
            <p>Every eligible person (normally) has already been contacted by the election committee via a postal election notification.</p>
        </SectionContainer>

        <SectionContainer style = {{backgroundColor: "white"}} id ="main-goals">
            <h1>What are the main goals of our two referendums?</h1>
            <ul>
                <li>
                    We need more safe and sufficiently wide cycle paths on which children 
                    and elderly people can cycle safely and independently through the city.
                </li>
                <li>
                    This requires a spatial separation and disentanglement
                    of cycle and car traffic.
                </li>
                <li>
                    It is not safe to cycle in large parts of Göttingen's cycle network. 
                    There are huge gaps between the city's neighbourhoods.
                </li>
            </ul>
            <p>
                With the two citizen referendums, we want to
                reduce the gaps.
            </p>
            <h1>
                How are these goals achieved?
            </h1>
            <p>
            The objectives will be achieved through the following key measures:
            </p>
            <ul>                
                <li>Separation of bikes and cars on busy roads.</li>
                <li>Protected bike lanes as safe and protected spaces for cycling.</li>
                <li>One-way street systems for safe traffic flow when the space for safe bicycle infrastructure is not available otherwise.</li>
                <li>Attractive design of cycle streets with minimum through traffic</li>
            </ul>
        </SectionContainer>
        <SectionContainer>
            <Slideshow images={plakatelist}/>
        </SectionContainer>
        <SectionContainer  style = {{backgroundColor: y}} id ="ways-to-vote">
            <h1>Ways to vote</h1>
            <div className="border-box">
                <h4>#1 Voting at the polling station on june 9th</h4>
                <p>
                    Every person entitled to vote will receive a polling notification 
                    for the two cycling referendums by post (separate from the European elections).
                </p>
                <p>
                The document indicates the polling station where the vote 
                can be cast on June 9th 2024 in parallel with the European elections.
                </p>
            </div>
            <div className="border-box">
                <h4>#2 Postal Vote</h4>
                <p>
                Each person entitled to vote will be sent a polling 
                notification for the two cycling referendums (separate from the European elections).
                </p>
                <p>
                    There is a QR code on the document that leads to a city website (wahlen.goettingen.de). 
                    After entering the personal data, the postal voting documents will 
                    be delivered within a few days. 
                    This needs to be done separately for the European election and the citizen referendums. 
                </p>
            </div>
            <div className="border-box">
                <h4>#3 Postal Voting Office</h4>
                <p>
                    Votes for the two cycling referendums can 
                    already be cast at the postal voting office of the City of Göttingen.
                </p>
                <p>
                    The postal voting office is located at Philipp-Reis-Str. 2 A, 37075 Göttingen, North Entrance (access via "Grüner Weg"). 
                    Further information: <a href = "https://wahlen.goettingen.de/portal/meldungen/briefwahlbuero-ab-13-mai-geoeffnet-900002931-25480.html?rubrik=900000002" target="_blank">Link to goettingen.de</a>
                </p>
            </div>
        </SectionContainer>

        <SectionContainer id = "join-us" style = {{backgroundColor: "rgb(196, 88, 196)"}}>
            <h1>You want to join us?</h1>
            <ul>
                <li><b>Send us an email:</b> <a href = "mailto:goettingenzero@posteo.de">goettingenzero@posteo.de</a></li>
                <li><b>Join our Signal Group:</b> <a href = "https://signal.group/#CjQKIOJBXMVGpC6er_ATt9sUWrpJvPFGtPw22-UBtNXmGQmLEhCvVeRinc7BtyhvW6qicut3" target = "_blank">Signal-Link</a></li>
            </ul>
        </SectionContainer>

        <SectionContainer id = "retrospect">
            <Dashboard lang = "en"/>
        </SectionContainer>

        <SectionContainer id = "supporter">
            <Supporter lang = "en"/>
        </SectionContainer>

        <SectionContainer>
            <DownloadsEn/>
        </SectionContainer>
        </div>
    );

}

export default EnglishSummary;