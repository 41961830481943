import React, { useState } from "react";
import CustomAdv from "../../components/AktuelleThemen/Reiter/CustomAdv";
import FullWidthContainer from "../../common/FullWidthContainer";
import Container from "../../common/Container";
import { Link } from "react-router-dom";
import Podcast from "../../components/AktuelleThemen/Reiter/Podcast";
import Button2 from "../../common/Button2";
import {PngIcon} from "../../common/PngIcon";

import "./style.css";

const PodcastPage = () => {
    return (
        <>
            <Container>
                <div className="intro">
                    <div className="left">
                        <h2>
                            Der Radentscheid <br/>Podcast.
                        </h2>
                        <ul>
                            <li>
                                Hier sind alle Episoden des Radentscheid Podcasts ohne Anmeldung verfügbar.
                            </li>
                        </ul>
                    </div>
                    <div className="right">
                        <PngIcon
                            src="_radentscheid-logo-14-04-2023.jpg"
                            width="250px"
                            height="fit-content"/>
                    </div>
                </div>

                <Podcast/>
            </Container>

            <FullWidthContainer>
                <div style={{backgroundColor: "white", padding: "4em", marginTop: "1em", marginBottom: "1em"}}>
                    <Container>
                        <h1>Weiterführende Links:</h1>
                        <ul style={{listStyleImage: "url(./img/icons/G0_Logo_Pfeil.png)"}}>
                            <li><Link to="../faq">Häufige Fragen (FAQ)</Link></li>
                            <li><Link to="../flyer">Radentscheid in der Kurzfassung (Web-Flyer)</Link></li>
                            <li><Link to="../news">Berichterstattung über den Radentscheid</Link></li>
                        </ul>
                    </Container>
                </div>
            </FullWidthContainer>
        </>
    );
}

export default PodcastPage;