import React from "react";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Container from "../../common/Container";

import './index.css';
import Button2 from "../../common/Button2";

const Slideshow = ({images}: {images: string[]}) => {

    return(
        <Container>
            <div className="slideshow-wrapper">
            <Slide>
                {
                    images.map(
                        i => {return(
                            <div className="each-slide-effect">
                                <a href = {i} target = '_blank'>
                                    {/* <Button2 content="Download" onClick={() => {}}/> */}
                                    <img src = {i}/>
                                </a>
                                {/* <div style={{ 'backgroundImage': `url('${i}')` }}>
                                    <span>{i}</span>
                                </div> */}
                            </div>
                        )}
                    )
                }
            </Slide>
            </div>
        </Container>
    )

}

export default Slideshow;