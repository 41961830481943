import React, {lazy} from "react";
import { Fade } from "react-awesome-reveal";
import "./style.css";
import { PngIcon } from "../../common/PngIcon";

import PresseContent from "../../content/presse_aktuelles.json";
import SectionLine from "../../components/SectionLine";


interface PresseKachelProps {
    title: string;
    source: string;
    content:string;
    date: string;
    url: string;

}

const PresseKachel = ({title,content, source, date, url}:PresseKachelProps) => {
    return(
        <div className="kachel" onClick={() => {
            window.open(url);
        }}
             onMouseEnter={() => {
                 document.body.style.cursor = 'pointer';
             }}
             onMouseLeave={() => {
                 document.body.style.cursor = 'default';
             }}>
            <h3>Datum: {date}</h3>
            <h2>{title}</h2>
            <p><i>"{content}"</i></p>
            <a href = {url} target = "_blank">Quelle: {source}</a>
        </div>
    );

}


const Presse = () => {
    return(
        <Fade>
            <div className="presse-wrapper">
                <div id='top'/>
                <div className="intro">
                    <div className="left">
                        <h1>Presse und Aktuelles</h1>
                        <h2>
                            News und Berichterstattungen <br/>zum Radentscheid.
                        </h2>
                    </div>
                    <div className="right">
                        <PngIcon
                            src="_radentscheid-logo-14-04-2023.jpg"
                            width="250px"
                            height="fit-content"/>
                    </div>
                </div>
                <SectionLine how="left"/>
                <div className="img-wrapper">
                    <img src="./img/png/uebergabe_unterschriften.jpg"
                         alt="Bild Übergabe Unterschriften Radentscheid (Quelle: priv. Foto)" width="100%"/>
                    <p>Quelle: Private Aufnahme</p>
                </div>
                <SectionLine how="right"/>
                <div className="berichte">
                    {
                        PresseContent.map(
                            b => {
                                return (
                                    <PresseKachel
                                        date={b.date}
                                        title={b.title}
                                        content={b.content}
                                        source={b.source}
                                        url={b.url}
                                    />

                                );
                            }
                        )
                    }
                </div>
            </div>
        </Fade>
    );
}

export default Presse;
